import services from "../services";

export const transactionEnquiryActions = {
  getTransactionEnquiry,
  udpateRecallAndRetrace,
  getTransactionsForReject,
  rejectTransaction,
  searchUpcomingRejectsRecalls,
  searchRecallPendingApprovals,
  recallTransaction
};

function getTransactionEnquiry(values, props) {
  return services.transactionEnquiryService.getTransactionEnquiry(
    values,
    props
  );
}

function udpateRecallAndRetrace(values, props) {
  return services.transactionEnquiryService.udpateRecallAndRetrace(
    values,
    props
  );
}

function getTransactionsForReject(values, props) {
  return services.transactionEnquiryService.getTransactionsForReject(
      values,
      props
  );
}

function rejectTransaction(values, props) {
  return services.transactionEnquiryService.rejectTransaction(
      values,
      props
  );
}

function searchUpcomingRejectsRecalls(values, props) {
  return services.upcomingRecallsRejectsService.searchUpcomingRejectsRecalls(values, props);
}

function searchRecallPendingApprovals(values, props) {
  return services.recallPendingApprovalsService.searchRecallPendingApprovals(values, props);
}

function recallTransaction(values, props) {
  return services.transactionEnquiryService.recallTransaction(
      values,
      props
  );
}
  
