import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Typography from "@mui/material/Typography";
import {Box} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import { withSidebar } from "../hoc/withSidebar";
import {useTranslation} from "react-i18next";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import {rejectTransactionsHeading
} from "../../constants/RejectTransactionsConstant";
import Alert from "@mui/material/Alert";

const RejectTransactions = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        history,
        location: { state, pathname },
    } = props;

    const [validationErrors, setValidationErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const errorRef = useRef();
    const [highlightError, setHighlightError] = useState({});
    const [loading, setLoading] = useState(false);
    const [transactionData, setTransactionData] = useState(null);

    const [financialInstitutionList, setFinancialInstitutionList] = useState([]);
    const initialStartDate = subtractMonths();
    const initialEndDate = new Date();

    const [initialLoading, setInitialLoading] = useState(false);
    const [prevBtnLoading, setPrevBtnLoading] = useState(false);
    const [nextBtnLoading, setNextBtnLoading] = useState(false);

    const [pageNumber, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(100);

    const billerDetails = [
        {name: "Biller ID", value: "bmoBillerId",},
        {name: "Biller Name", value: "billerName",},

    ]
    const user = useSelector((state) => state.loginUserReducer.user);
    const roleId = user?.role?.role_id;

    const financialInstitutionResponse = useSelector(
        (state) => state.fiReducer?.financialInstitutionList
    );

    const rejectTransactionResponse = useSelector(
        (state) => state.transactionEnquiryReducer
    );

    const alertRef = useRef(null);


    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [validationErrors.length, errorMessage]);

    useEffect(() => {
        if (alertRef.current && transactionData?.length > 100) {
            alertRef.current.focus();
        }
    }, [transactionData && transactionData?.length > 100]);

    const getMinDateValue = (date, days) => {
        const minDateVal = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - days + 1
        );
        return minDateVal;
    };

    const MIN_START_DATE_VALUE = getMinDateValue(initialStartDate, 60);
    const MIN_END_DATE_VALUE = getMinDateValue(initialEndDate, 90);
    const MAX_DATE_VALUE = new Date("2100-01-01");


    function subtractMonths(date = new Date()) {
        date.setMonth(date.getMonth());
        date.setDate(date.getDate() - 31);
        return date;
    }


    const [searchValues, setSearchValue] = useState({
        paymentFromDate: initialStartDate,
            paymentToDate: initialEndDate,
            transactionToAmount: "",
            verificationNumber: "",
            searchBy: "bmoBillerId",
            bmoBillerId: "",
            billerName: "",
            billerCustomerNumber: "",
            fiCustomerName: "",
            fiNumber: "0",
            minStartDateValue: MIN_START_DATE_VALUE,
            minEndDateValue: MIN_END_DATE_VALUE,
            maxDateValue: MAX_DATE_VALUE,
            startOffset: 0,
            endOffset: 100,
            sortBy: "ED",
    });


    useEffect(() => {
        dispatch(actions.fiActions.getFinancialInstitutionList(t));
        if (state?.searchedValues) {
            setSearchValue(state?.searchedValues);
        }
    }, []);

    useEffect(() => {
        if (financialInstitutionResponse && financialInstitutionResponse.length) {
            const data = financialInstitutionResponse.map(
                ({ fi_name, fi_number }) => ({
                    value: fi_number,
                    label: `${fi_number} - ${fi_name}`,
                })
            );
            data.unshift({
                value: "0",
                label: "All Fi's",
            });
            setFinancialInstitutionList(data);
        }
    }, [financialInstitutionResponse]);


    useEffect(() => {
        if (rejectTransactionResponse) {
            const transactionForRejectRes =
                rejectTransactionResponse?.transactionForRejectData?.data;
            if (transactionForRejectRes && transactionForRejectRes?.length) {
                const updatedRes = transactionForRejectRes?.map((d, idx) => ({
                    ...d,
                    id: idx,
                }));
                setTransactionData(updatedRes);
            } else {
                setTransactionData(transactionForRejectRes);
            }
        }
        if (
            state?.fromDetailPage &&
            rejectTransactionResponse?.transactionForRejectDetails
        ) {
            const data = getPaginationProps(
                rejectTransactionResponse?.transactionForRejectDetails
            );
            const page = data?.pageNumber || pageNumber;
            const row = data?.rowPerPage || rowPerPage;
            setPage(page);
            setRowPerPage(row);
            setSearchValue(rejectTransactionResponse?.transactionForRejectDetails);


            if (state?.isRejected) {
                const searchRequest = {
                    ...rejectTransactionResponse?.transactionForRejectDetails,
                    transactionIsRejected: "Y",
                    startOffset: page * row,
                    endOffset: page * row + row,
                    count: 0,
                };

                setInitialLoading(true);
                if (errorMessage) {
                    setErrorMessage(null);
                }

                state.isRejected = false;

                dispatch(
                    actions.transactionEnquiryActions.getTransactionsForReject(searchRequest, {...props, t, setLoading: setInitialLoading, setErrorMessage})
                );
            }

        }
    }, [rejectTransactionResponse]);


    const isCharOccurringOnce = (str, char) => {
        let occurrences = str?.split(char)?.length;
        if (typeof occurrences === "number") {
            occurrences -= 1;
            return occurrences === 1;
        }
    };

    const handleChange = (key, value) => {
        setSearchValue((prevState) => ({
            ...prevState,
            [key]: value,
        }));

    };

    const validateSearchValues = (searchReqValues) => {
        const {
            paymentFromDate,
            paymentToDate,
            transactionToAmount,
            fiNumber,
            verificationNumber,
            bmoBillerId,
            billerName,
            fiCustomerName,
            billerCustomerNumber,
            searchBy
        } = searchReqValues;
        const parsedPaymentAmount = transactionToAmount
            ? parseFloat(transactionToAmount)
            : undefined;
        let errors = [];
        let highlightErrorV1 = {};
        const date1 = new Date(paymentFromDate);
        const date2 = new Date(paymentToDate);
        const decimalRegEx = /^\d*(\.\d{0,2})*$/;
        const alphaNumericRegEx = /^[0-9A-Za-z\s]+$/;
        const alphaNumericWithHypenRegEx = /^[0-9A-Za-z-%\s]+$/;
        const billerNameRegex =
            /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
        const billerNameWildCharRegex =
            /^(?:%?[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+%?)$/;


        if (!paymentFromDate) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.paymentFromDate.required")
            );
            highlightErrorV1 = { ...highlightErrorV1, paymentFromDate: true };
        }
        if (!paymentToDate) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.paymentToDate.required")
            );
            highlightErrorV1 = { ...highlightErrorV1, paymentToDate: true };
        }
        if (date2.getTime() < date1.getTime()) {
            errors.push(t("erps.transactionEnquiry.page.errorMessage.fromDate"));
            highlightErrorV1 = {
                ...highlightErrorV1,
                paymentFromDate: true,
                paymentToDate: true,
            };
        } else if (
            searchValues.minStartDateValue &&
            searchValues.minEndDateValue &&
            searchValues.maxDateValue
        ) {
            const minStartDate = searchValues.minStartDateValue;
            const minEndDate = searchValues.minEndDateValue;
            const maxDate = searchValues.maxDateValue;
            if (paymentFromDate && date1.getTime() < minStartDate.getTime()) {
                errors.push(t("erps.common.datepicker.startDatenotbeforemaximaldate"));
                highlightErrorV1 = {
                    ...highlightErrorV1,
                    startDate: true,
                };
            } else if (paymentFromDate && date1.getTime() > maxDate.getTime()) {
                errors.push(t("erps.common.datepicker.startdatenotaftermaximaldate"));
                highlightErrorV1 = {
                    ...highlightErrorV1,
                    startDate: true,
                };
            }
            if (paymentToDate && date2.getTime() < minEndDate.getTime()) {
                errors.push(t("erps.common.datepicker.endDatenotbeforemaximaldate"));
                highlightErrorV1 = {
                    ...highlightErrorV1,
                    endDate: true,
                };
            } else if (paymentToDate && date2.getTime() > maxDate.getTime()) {
                errors.push(t("erps.common.datepicker.endDatenotaftermaximaldate"));
                highlightErrorV1 = {
                    ...highlightErrorV1,
                    endDate: true,
                };
            }
        }

        if (parsedPaymentAmount !== undefined) {
            if (parsedPaymentAmount < 0) {
                errors.push(
                    t(
                        "erps.transactionEnquiry.page.errorMessage.paymentFromAmountGreaterThan0"
                    )
                );
                highlightErrorV1 = { ...highlightErrorV1, transactionFromAmount: true };
            }

            if (
                parsedPaymentAmount > 0 &&
                !decimalRegEx.test(parsedPaymentAmount)
            ) {
                errors.push(
                    t("erps.transactionEnquiry.page.errorMessage.paymentFromDecimalValue")
                );
                highlightErrorV1 = { ...highlightErrorV1, transactionFromAmount: true };
            }
        }



        if (
            verificationNumber &&
            !alphaNumericWithHypenRegEx.test(verificationNumber)
        ) {
            errors.push(
                t(
                    "erps.transactionEnquiry.page.errorMessage.verificationNumber.invalid"
                )
            );
            highlightErrorV1 = { ...highlightErrorV1, verificationNumber: true };
        }

        if (billerCustomerNumber && !alphaNumericRegEx.test(billerCustomerNumber)) {
            errors.push(
                t(
                    "erps.transactionEnquiry.page.errorMessage.customerAccountNumber.invalid"
                )
            );
            highlightErrorV1 = { ...highlightErrorV1, billerCustomerNumber: true };
        }

        if (fiCustomerName && !alphaNumericWithHypenRegEx.test(fiCustomerName)) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.customerName.invalid")
            );
            highlightErrorV1 = { ...highlightErrorV1, fiCustomerName: true };
        }


        if (
            searchBy === "bmoBillerId" && !bmoBillerId
        ) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.billerId.required")
            );
            highlightErrorV1 = { ...highlightErrorV1, billerId: true };
        }

        if (
            searchBy === "billerName" && !billerName
        ) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.billerName.required")
            );
            highlightErrorV1 = { ...highlightErrorV1, billerName: true };
        }

        if (bmoBillerId && !alphaNumericRegEx.test(bmoBillerId)) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.billerId.invalid")
            );
            highlightErrorV1 = { ...highlightErrorV1, billerId: true };
        }

        if (billerName && !billerNameRegex.test(billerName)) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.billerName.invalid")
            );
            highlightErrorV1 = { ...highlightErrorV1, billerName: true };
        } else if (
            billerName &&
            ((billerName.includes("%") && billerName.length < 4) ||
                !billerNameWildCharRegex.test(billerName) ||
                (billerName.includes("%") && !isCharOccurringOnce(billerName, "%")))
        ) {
            errors.push(
                t("erps.transactionEnquiry.page.errorMessage.billerName.minChar")
            );
            highlightErrorV1 = { ...highlightErrorV1, billerName: true };
        }


        return { errors, highlightErrorV1 };
    };

    const handleSearch = () => {
        const actualCriteria = sanitizeReqBody({ ...searchValues });
        const { errors, highlightErrorV1 } = validateSearchValues(actualCriteria);
        const {
            startOffset,
            endOffset,
            searchBy,
            minStartDateValue,
            minEndDateValue,
            maxDateValue,
            count,
            ...rest
        } = actualCriteria;

        if (errors.length) {
            setValidationErrors(errors);
            setHighlightError(highlightErrorV1);
        } else if (
            rest &&
            typeof rest === "object" &&
            Object.keys(rest)?.length < 3
        ) {
            setValidationErrors([
                t(
                    t(
                        "erps.transactionEnquiry.page.errorMessage.minimumthreefields.required"
                    )
                ),
            ]);
        } else {
            setValidationErrors([]);
            setHighlightError({});
            setLoading(true);
            if (pageNumber) {
                setPage(0);
            }
            dispatch(
                actions.transactionEnquiryActions.getTransactionsForReject(
                    {
                        ...actualCriteria,
                        count: 0,
                        startOffset: 0,
                        endOffset: 100,
                    },
                    { ...props, t, history, setLoading, setErrorMessage}
                )
            );

        }
    };

    const sanitizeReqBody = (reqBody) => {
        let updatedReqBody;
        Object.keys(reqBody).forEach((key) => {
            if (
                reqBody[key] === undefined ||
                ("string" === typeof reqBody[key] && reqBody[key].trim() === "")
            ) {
                delete reqBody[key];
            } else {
                if (typeof reqBody[key] === "string") {
                    updatedReqBody = { ...updatedReqBody, [key]: reqBody[key].trim() };
                } else {
                    updatedReqBody = { ...updatedReqBody, [key]: reqBody[key] };
                }
            }
        });

        return updatedReqBody;
    };

    const handleReset = () => {
        setHighlightError({});
        setSearchValue({
            paymentFromDate: initialStartDate,
            paymentToDate: initialEndDate,
            transactionToAmount: "",
            verificationNumber: "",
            searchBy: "bmoBillerId",
            bmoBillerId: "",
            billerName: "",
            billerCustomerNumber: "",
            fiCustomerName: "",
            fiNumber: "0",
            minStartDateValue: MIN_START_DATE_VALUE,
            minEndDateValue: MIN_END_DATE_VALUE,
            maxDateValue: MAX_DATE_VALUE,
            sortBy: "ED",
        });
        setValidationErrors([]);
        setPage(0);
        setErrorMessage(null);

        dispatch({
          type: "GET_TRANSACTION_FOR_REJECT_SUCCESS",
          data: { data: null, transactionForRejectDetails: null },
        });

    };

    const getPaginationProps = (data) => {
        if (state?.fromDetailPage && data) {
            const { startOffset, endOffset } = data;
            const rowPerPage = endOffset - startOffset;
            const pageNumber = endOffset / (endOffset - startOffset) - 1;
            return { rowPerPage, pageNumber };
        }
        return "";
    };

    const paginateTransactionsForReject = (values) => {
        let setBtnLoading;
        const actualCriteria = sanitizeReqBody({ ...values });
        if (actualCriteria.startOffset < actualCriteria.endOffset) {
            if (actualCriteria?.actionType === "prev") {
                setBtnLoading = setPrevBtnLoading;
                setBtnLoading(true);
            } else if (actualCriteria?.actionType === "next") {
                setBtnLoading = setNextBtnLoading;
                setBtnLoading(true);
            }
            setInitialLoading(true);
            const searchRequest = {
                ...searchValues,
                transactionIsRejected: "N",
                startOffset: actualCriteria.startOffset,
                endOffset: actualCriteria.endOffset,
                count: 0,
            };

            if (errorMessage) {
                setErrorMessage(null);
            }

            dispatch(
                actions.transactionEnquiryActions.getTransactionsForReject(searchRequest, {
                    ...props,
                    t,
                    setErrorMessage,
                    setLoading: setInitialLoading,
                    setBtnLoading,
                })
            );
        }
    };


    return (
        <div role="main">
            <Box ml={3}>
                {validationErrors.length || errorMessage ? (
                    <Box
                        className="manageUsers__errorMsg__container"
                        role="alert"
                        mt={validationErrors.length || errorMessage ? 2 : 0}
                    >
                        {validationErrors.length || errorMessage ? (
                            <ErrorMessage
                                error={
                                    validationErrors?.length ? validationErrors : errorMessage
                                }
                                errorRef={errorRef}
                            />
                        ) : (
                            ""
                        )}
                    </Box>
                ) : (
                    ""
                )}

                <Box className="manageUsers__page__title">
                    <CommonContainerBox title={t("erps.rejectpaymentmerchants.page.title")} />
                    <Typography variant="h1">
                        {t("erps.rejectpaymentmerchants.page.title")}
                    </Typography>
                </Box>
                <Box mt={6}>
                    <Typography variant="h2">
                        {t("erps.transactionEnquiry.page.description")}
                    </Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1">
                        {t("erps.transactionEnquiry.page.wildcardDescription")}
                    </Typography>
                </Box>
                <Box mt={2}>
                    <Typography variant="h3">
                        {t(
                            "erps.transactionEnquiry.page.transactionDetails.page.subheading"
                        )}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <ERPSBaseDatePicker
                        label={t("erps.availabledocuments.page.startdate", "Start date")}
                        onChange={(e) => handleChange("paymentFromDate", e)}
                        value={searchValues?.paymentFromDate}
                        className="app_datepicker_biller_transactionEnquiry"
                        datePickerStyle={"quick-reg-datepicker-v1"}
                        maxDate={searchValues.maxDateValue}
                        minDate={searchValues.minStartDateValue}
                        error={highlightError?.paymentFromDate}
                        required={true}
                        id="reject_payments_startDate"
                    />
                    <ERPSBaseDatePicker
                        label={t("erps.availabledocuments.page.enddate", "End date")}
                        onChange={(e) => handleChange("paymentToDate", e)}
                        value={searchValues?.paymentToDate}
                        className="app_datepicker_biller_transactionEnquiry"
                        datePickerStyle={"quick-reg-datepicker-v1"}
                        maxDate={searchValues.maxDateValue}
                        minDate={searchValues.minEndDateValue}
                        error={highlightError?.paymentToDate}
                        required={true}
                        id="reject_payments_endDate"
                    />
                    <ERPSBaseTextField
                        id="financialInstitution"
                        type="text"
                        select={true}
                        label={t("erps.newuser.label.fi", "Financial Institution")}
                        className="three_column__textField"
                        value={searchValues?.fiNumber}
                        onChange={(e) => handleChange("fiNumber", e.target.value)}
                        size="small"
                        required="true"
                        items={financialInstitutionList}
                        SelectProps={{ native: true }}
                        InputLabelProps={{ shrink: searchValues?.fiNumber ? true : false }}
                    />
                </Stack>
                <Stack direction="row" spacing={2}>
                    <ERPSBaseTextField
                        id="paymentAmount"
                        type="number"
                        allowDecimal={true}
                        label={t("erps.rejectpaymentmerchants.page.label.paymentamount")}
                        className="transactionEnquiry__textField__width"
                        size="small"
                        onChange={(e) =>
                            handleChange("transactionToAmount", e.target.value)
                        }
                        value={searchValues?.transactionToAmount}
                        error={highlightError?.transactionToAmount}
                    />
                    {/*Confirmation number input*/}
                    <ERPSBaseTextField
                        id="verificationNumber"
                        type="number"
                        allowDecimal={false}
                        label={t("erps.transactionEnquiry.page.confirmationnumber")}
                        className="transactionEnquiry__textField__width"
                        size="small"
                        onChange={(e) =>
                            handleChange("verificationNumber", e.target.value)
                        }
                        value={searchValues?.verificationNumber}
                        error={highlightError?.verificationNumber}
                    />
                </Stack>
                <Box mt={4}>
                    <Typography variant="h3">
                        {t(
                            "erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails"
                        )}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <Box mt={2}>
                        <ERPSBaseRadioGroup
                            name="searchBy"
                            marginTop={"true"}
                            InputLabelProps={{ shrink: true }}
                            value={searchValues?.searchBy}
                            label={t("erps.transactionEnquiry.page.label.searchBy")}
                            onChange={(e) => handleChange("searchBy", e.target.value)}
                            items={billerDetails}
                            error={highlightError?.searchBy}
                            labelPlacement="start"
                            className={"transactionEnquiry__radioButton_direction"}
                        />
                    </Box>
                    <Box style={{ marginTop: "-30px" }}>
                        {searchValues?.searchBy === "bmoBillerId" && (
                            <ERPSBaseTextField
                                id="bmoBillerId"
                                type="text"
                                label={t("erps.transactionEnquiry.page.label.billerId")}
                                className="transactionEnquiry__textField__width"
                                size="small"
                                required={"true"}
                                onChange={(e) => handleChange("bmoBillerId", e.target.value)}
                                value={searchValues?.bmoBillerId}
                                error={highlightError?.bmoBillerId}
                                inputProps={{ maxLength: 9 }}
                            />
                        )}
                        {searchValues?.searchBy === "billerName" && (
                            <ERPSBaseTextField
                                id="billerName"
                                type="text"
                                label={t("erps.transactionEnquiry.page.label.billerName")}
                                className="transactionEnquiry__textField__width"
                                required={"true"}
                                size="small"
                                onChange={(e) => handleChange("billerName", e.target.value)}
                                value={searchValues?.billerName}
                                error={highlightError?.billerName}
                                inputProps={{ maxLength: 45 }}
                            />
                        )}
                    </Box>
                </Stack>
                <Box mt={4}>
                    <Typography variant="h3">
                        {t(
                            "erps.transactionEnquiry.componentToPrint.page.subheading.customerDetails"
                        )}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                    <ERPSBaseTextField
                        id="customerNumber"
                        type="text"
                        label={t("erps.transactionEnquiry.page.label.customerFiNumber")}
                        className="transactionEnquiry__textField__width"
                        size="small"
                        onChange={(e) =>
                            handleChange("billerCustomerNumber", e.target.value)
                        }
                        value={searchValues?.billerCustomerNumber}
                        error={highlightError?.billerCustomerNumber}
                        inputProps={{ maxLength: 45 }}

                    />
                    <ERPSBaseTextField
                        id="customerName"
                        type="text"
                        label={t("erps.transactionEnquiry.page.label.customerFiName")}
                        className="transactionEnquiry__textField__width"
                        size="small"
                        onChange={(e) => handleChange("fiCustomerName", e.target.value)}
                        value={searchValues?.fiCustomerName}
                        error={highlightError?.fiCustomerName}
                        inputProps={{ maxLength: 30 }}
                    />
                </Stack>
                {transactionData && transactionData?.length > 100 ? (
                    <Stack
                        sx={{ width: "100%" }}
                        spacing={2}
                        mt={4}
                        tabIndex={0}
                        ref={alertRef}
                    >
                        <Alert severity="info">
                            {t("erps.transactionenquiry.info.message")}
                        </Alert>
                    </Stack>
                ) : (
                    ""
                )}
                <Box display="flex">
                    <ERPSBaseButton
                        variant="secondary"
                        className="button_with_margin48"
                        label={t("erps.transactionEnquiry.page.button.reset")}
                        onClick={handleReset}
                    />
                    <Box pl={2}>
                        <ERPSBaseButton
                            variant="primary"
                            className="button_with_margin48"
                            label={t("erps.transactionEnquiry.page.button.search")}
                            loading={loading}
                            onClick={handleSearch}
                        />
                    </Box>
                </Box>
                <Box mt={6}>
                    <Typography variant="h2">
                        {" "}
                        {t("erps.manageusers.searchuser.searchresults")}
                    </Typography>
                </Box>
                {transactionData !== null &&
                transactionData !== undefined &&
                transactionData.length > 0 ? (
                    <>
                        <Box className="manageUsers_table_visible">
                            <ERPSGrid
                                data={{
                                    rows: transactionData,
                                    columns: rejectTransactionsHeading(
                                        roleId,
                                        user?.fiNumber
                                    ),
                                }}
                                onAnyPaginationAction={paginateTransactionsForReject}
                                dataGridWidth={
                                         "dataGrid__box__container__transactionEnquiry_v3"
                                }
                                initialLoading={initialLoading}
                                pageNumber={pageNumber}
                                setPage={setPage}
                                rowPerPage={rowPerPage}
                                tableLabelText={t("erps.transactionEnquiry.page.title")}
                                hideFooterPagination={true}
                                pageName={"transaction-enquiry"}
                                prevBtnLoading={prevBtnLoading}
                                nextBtnLoading={nextBtnLoading}
                            />
                        </Box>
                        <Box className="manageUsers_card_visible">
                            <CardList
                                data={{
                                    rows: transactionData,
                                    columns: rejectTransactionsHeading(
                                        roleId,
                                        user?.fiNumber
                                    ),
                                }}
                                onAnyPaginationAction={paginateTransactionsForReject}
                                hideFooterPagination={true}
                                pageName={"transaction-enquiry"}
                                pageNumber={pageNumber}
                                setPage={setPage}
                                rowPerPage={rowPerPage}
                                prevBtnLoading={prevBtnLoading}
                                nextBtnLoading={nextBtnLoading}
                            />
                        </Box>
                    </>
                ) : (
                    ""
                )}
            {(transactionData === null || transactionData === undefined) && (
                <Box mt={1}>
                    <Typography variant="body1">
                        {" "}
                        {t(
                            "erps.availabledocuments.searchuser.notstarted",
                            "Search not started"
                        )}
                    </Typography>
                </Box>
            )}
            {Array.isArray(transactionData) && transactionData?.length === 0 && (
                <Box mt={1}>
                    <Typography variant="body1">
                        {" "}
                        {`0 ${t("erps.transactionEnquiry.page.resultfound")}`}
                    </Typography>
                </Box>
            )}

            </Box>
        </div>
    );

};

export default withSidebar(RejectTransactions);