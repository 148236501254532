import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import { useTranslation } from "react-i18next";

import { convertDateIntoERPSDateFormat, formatAmount, getFormatDetails } from "../../utils/helper";

const ERPSTransactionDetails = (props) => {
  const {
    data,
  } = props;
  const { t } = useTranslation();

  const displayPaymentTime = (paymentTime) => {
    if (paymentTime) {
        const parsedPaymentTime = paymentTime.toString();
// WHY PADDING TO 8 ???
        const updatedPaymentTime = parsedPaymentTime.padStart(8, "0");
        return updatedPaymentTime.replace(/(..)(?!$)/g, "$1:");

// MAYBE REPLACE WITH THIS ??
        // const updatedPaymentTime = paymentTime.toString().padStart(6, "0");
        // return updatedPaymentTime.replace(/(\d\d)(\d\d)(\d\d)/g, "$1:$2:$3");
      }
};

  return (
    <>
      <Box className="manageUsers__page__title" sx={{ ml: 3, mb:3 }}>
        {/* For Title update */}
        <CommonContainerBox title={t("erps.transactionEnquiry.page.transactionDetails.page.heading")}/>
        <Typography variant="h1">{t("erps.transactionEnquiry.page.transactionDetails.page.heading")}</Typography>
      </Box>
      <Box id={"screenshot-element"} sx={{ ml: 3 }}>
        <Box sx={{ mb: -2 }}>
          <Typography variant="h3">
            {t("erps.transactionEnquiry.page.transactionDetails.page.subheading")}
          </Typography>
        </Box>
        <Box sx={{ mt: 3, display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.label.effectiveDate")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data.effective_date) }
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.label.paymentDate/Time")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data.payment_date) +
                " " + displayPaymentTime(data?.payment_time)?.substring(0, 8) }
            </Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.label.deliveryDate")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data.delivery_date) }
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 3, display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.label.paymentAmount")}
            <Typography variant="body2">{formatAmount(data.transaction_amount)}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.label.confirmationNumber")}
            <Typography variant="body2">{data.verification_number}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.label.auditNumber")}
            <Typography variant="body2">{data.biller_file_number}</Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 3, display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.label.transactionOrigination")}
            <Typography variant="body2">{`${ data?.cap_type || ""} ${ data.caption_type_description || "" }`}</Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h3">
            {t("erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails")}
          </Typography>
        </Box>

        <Box sx={{ mt: 3, display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.billerDetails.label.corporateBiller")}
            <Typography variant="body2">
              {data.biller_name} <br />
              {t("erps.common.label.attention")} {data.primary_contact_name}{" "}
              <br />
              {t("erps.common.label.telephone")}{" "}
              {getFormatDetails(data.primary_contact_phone)} <br />
              {t("erps.common.label.fax")}{" "}
              {getFormatDetails(data.primary_contact_fax)} <br />
            </Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.billerDetails.label.billerId")}
            <Typography variant="body2">{data?.display_biller_id}</Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 4}}>
          <Typography variant="h3">{t("erps.transactionEnquiry.componentToPrint.page.subheading.customerDetails")}</Typography>
        </Box>
        <Box sx={{ mt: 3, display: "flex" }}>
          <Typography className="transaction__enquiry__value">
            {t("erps.transactionEnquiry.componentToPrint.customerDetails.label.customerAccountNumber")}
            <Typography variant="body2">{data.biller_customer_number}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">
            {t("erps.transactionEnquiry.componentToPrint.customerDetails.label.customerName")}
            <Typography variant="body2">{data.fi_customer_name}</Typography>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default ERPSTransactionDetails;
